<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <template v-if="page">
            {{ page.title }}
          </template>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div class="action-input-wrapper">
            <v-select
              v-model="filter"
              :items="testTypes"
              item-text="label"
              item-value="id"
            >
            </v-select>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </div>
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headers"
          :footer-props="{ 'items-per-page-options': [-1, 15, 30, 50, 100] }"
          :items="selfTests.length ? selfTests : []"
          item-key="id"
          class="elevation-1 table-one"
          multi-sort
        >
          <template v-slot:item.test_type="{ item }">
            {{ item.test_type == 1 ? "SOGS" : "PGSI/CPGI" }}
          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="$router.push(`/app/self-test/${item.id}/edit`)"
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from "@/store";
import _ from "lodash";
import moment from "moment";

export default {
  metaInfo: {
    title: "Self test"
  },
  data() {
    return {
      model: "self-test",
      page: null,
      search: "",
      selectedId: null,
      filter: null,
      selfTests: [],
      testTypes: [
        { id: 0, label: "All" },
        { id: 1, label: "SOGS" },
        { id: 2, label: "PGSI/CPGI" }
      ],
      headers: [
        { text: "Question Number", value: "question_number" },
        { text: "Body NL", value: "body_nl" },
        { text: "Test Type", value: "test_type" },
        { text: "Order Number", value: "order" },
        { text: "Action", value: "action", sortable: false }
      ]
    };
  },
  mounted() {
    this.filter = 0;
    this.page = store.getters.getPage({ model: this.model });
  },
  methods: {
    ...mapActions(["removeSelfTest"])
  },
  computed: {
    ...mapGetters(["getSelfTests", "getSelfTestLoading"])
  },
  watch: {
    filter(testTypeId) {
      if (!testTypeId) {
        this.selfTests = this.getSelfTests;
        return;
      }

      this.selfTests = _.filter(this.getSelfTests, { test_type: testTypeId });
    }
  },
  filters: {
    datetime(value) {
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    }
  }
};
</script>
<style lang="scss" scoped>
.action-input-wrapper {
  display: flex;
  justify-content: space-between;
  width: 500px;
  & > div {
    max-width: 240px;
  }
}

::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
