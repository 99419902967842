var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',[(_vm.page)?[_vm._v(" "+_vm._s(_vm.page.title)+" ")]:_vm._e(),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('div',{staticClass:"action-input-wrapper"},[_c('v-select',{attrs:{"items":_vm.testTypes,"item-text":"label","item-value":"id"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],2),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"search":_vm.search,"headers":_vm.headers,"footer-props":{ 'items-per-page-options': [-1, 15, 30, 50, 100] },"items":_vm.selfTests.length ? _vm.selfTests : [],"item-key":"id","multi-sort":""},scopedSlots:_vm._u([{key:"item.test_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.test_type == 1 ? "SOGS" : "PGSI/CPGI")+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.$router.push(("/app/self-test/" + (item.id) + "/edit"))}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }